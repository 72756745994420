import React, { useContext, useEffect, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs'; // Importing the Back Icon from react-icons library
import PreviewTwo from './Preview';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import UpdateInstanceTwo from './UpdateInstance';
import DeleteInstanceTwo from './DeleteInstance';
import LoadingComponent from '../../components/LoadingComponent'
import { TemplateTwoContext } from './TemplateTwoContext';
import TestInstanceTwo from './TestInstance';
import axiosInstance from '../../utils/RequestsQueue';

export default function InstanceDetails() {

  const authToken = localStorage.getItem('authToken')
   const navigate = useNavigate()
   const {instanceId} = useParams()
  //  const [instance,setInstance] = useState({})
   const [update, setUpdate] = useState(false)
   const {temp2Change, sendTemp2Instance,loading,setLoading,instance,setInstance} = useContext(TemplateTwoContext)
  //  const [loading,setLoading] = useState(false)

  useEffect(() => {
    if (authToken && instanceId) {
      setLoading(true);
      axiosInstance.requestWithQueue({
        url: `https://beshapp.frontend.safarinetics.com/template2/${instanceId}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      })
      .then(response => {
        setInstance(response.data);
      })
      .catch(error => {
        console.error('Error fetching instance:', error);
      })
      .finally(() => {
        setLoading(false); // This will run regardless of success or error
      });
    }
  }, [instanceId, authToken, temp2Change]);

   if (loading){
    return(
      <LoadingComponent loadingText={'Loading ....'} />
    )
   }
   
  return (
    <div className="container-fluid my-4 shadow-lg">
      <div className="card">
        <div className="card-header bg-dark text-white">
          <div className="d-flex align-items-center justify-content-center">
            <h3 className="mb-0 ">{instance?.subject}</h3>
          </div>
        </div>
        <div className="card-body">
          {/* Header Bar with Buttons */}
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
            <div>
              <button onClick={() => navigate('/templates/2/templates')} className="btn btn-outline-primary btn-sm mr-2"><BsArrowLeft /> Instances</button>

            </div>
            <div className="d-flex flex-wrap">
            {/* Conditional rendering for buttons based on `update` state */}
            {!instance?.sent && (
              <>
              {!update ? (<button
                className='btn btn-sm btn-primary mx-1'
                 onClick={()=> {
                  navigate(`/templates/2/templates/${instanceId}/update`)
                  setUpdate(true)
                }}>Update</button>
                ):(
                  <button
                  className='btn btn-sm btn-primary mx-1'
                   onClick={()=> {
                    navigate(`/templates/2/templates/${instanceId}`)
                    setUpdate(false)
                  }}>View</button>
                )}
                
                
                <button onClick={() => sendTemp2Instance(instanceId)} className="btn btn-primary btn-sm mx-1">
                  Send
                </button>
                
                <DeleteInstanceTwo />
              </>
            )}
            
            {/* `TestInstanceTwo` is always rendered */}
            <TestInstanceTwo />
          </div>
           
          </div>
          {/* Other content of instance details */}
          <div className="row">
            <Outlet/>
            {/* {!update && (<PreviewTwo instance={instance}/>)}
            {update && (<UpdateInstanceTwo instance={instance} />)} */}
          </div>
        </div>
      </div>
    </div>
  );
}
