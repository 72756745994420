import axios from 'axios';

class RequestQueue {
  constructor() {
    this.queue = [];
    this.isProcessing = false;
  }

  // Helper function to create a delay
  delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async processQueue() {
    if (this.isProcessing || this.queue.length === 0) {
      // console.log('Queue is either processing or empty. Skipping.');
      return;
    }

    this.isProcessing = true;
    // console.log('Processing queue:', this.queue);

    while (this.queue.length > 0) {
      const { config, resolve, reject } = this.queue.shift();
      // console.log('Processing request with config:', config);
      
      let attempts = 0;
      const MAX_ATTEMPTS = 3;

      while (attempts < MAX_ATTEMPTS) {
        try {
          const response = await axios(config);
          
          // Check if response.data is empty
          if (!response.data || (typeof response.data === 'string' && response.data.trim() === '')) {
            attempts++;
            // console.warn(`Attempt ${attempts} failed with empty response. Retrying in 1 second...`);
            
            // Delay before retrying
            await this.delay(2000);
            if (attempts === MAX_ATTEMPTS) {
              // console.error('Max attempts reached. Adding request back to queue.');
              this.queue.push({ config, resolve, reject }); // Re-add to the back of the queue
            }
            continue; // Retry the request
          }

          // console.log('Request successful:', response);
          resolve(response);
          break; // Exit the retry loop on success

        } catch (error) {
          // console.log('Request failed with error:', error);

          if (error.code === 'ECONNABORTED') {
            // console.warn('Request timed out. Retrying...');
            this.queue.push({ config, resolve, reject }); // Re-add to the back of the queue
          } else {
            reject(error);
          }
          break; // Exit the retry loop on error
        }
      }
    }

    this.isProcessing = false;
    // console.log('Finished processing queue. Queue is now empty.');
  }

  requestWithQueue(config) {
    // console.log('Adding request to queue with config:', config);

    return new Promise((resolve, reject) => {
      this.queue.push({ config, resolve, reject });
      // console.log('Current queue state:', this.queue);
      this.processQueue();
    });
  }
}

const requestQueue = new RequestQueue();

const axiosInstance = {
  requestWithQueue: requestQueue.requestWithQueue.bind(requestQueue),
};

export default axiosInstance;
