import React, { useContext } from 'react';
import backgroundImage from '../images/sn_logo.png';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

export default function Setting() {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  return (
    <div className="container-fluid" style={{ padding: '20px', backgroundColor: '#f0f0f0', minHeight: '100vh' }}>
      <div style={{ textAlign: 'center', marginBottom: '30px' }}>
        <h2 style={{ fontFamily: 'Inknut Antiqua, serif', marginBottom: '10px', color: '#333' }}>Settings</h2>
        <img src={backgroundImage} alt="Logo" style={{ width: '150px', height: 'auto' }} /> {/* Smaller image */}
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center', // Center vertically
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto', // Center horizontally
        top: '50%', // Move down
      }}>
        <h4 style={{ fontFamily: 'Arial, sans-serif', fontSize: '1.5rem', color: '#007bff', marginBottom: '10px' }}>Profile Information</h4>
        <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '1.2rem', marginBottom: '15px', color: '#555' }}>
          Name: <strong>{currentUser?.name}</strong>
        </p>
        <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '1.2rem', marginBottom: '15px', color: '#555' }}>
          Email: <strong>{currentUser?.email}</strong>
        </p>
        <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '1.2rem', marginBottom: '15px', color: '#555' }}>
          Registered On: <strong>{currentUser?.registered_at}</strong>
        </p>
        <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '1.2rem', marginBottom: '15px', color: '#555' }}>
          Last Updated: <strong>{currentUser?.updated_at}</strong>
        </p>
        <button
          style={{
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
            marginTop: '20px',
          }}
          onClick={() => navigate('/settings/auth/password/change')}
        >
          Change Password
        </button>
      </div>
    </div>
  );
}
